import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../utils/media';
import useIdentity from '../utils/useIdentity';
import { debounce } from '../utils/utils';
import useMLab from '../utils/useMLab';

const ClapsBtn = styled.div`
  transition: 0.3s ease-in transform;
  @media ${media.tablet} {
    display: none;
  }
  @media ${media.phone} {
    display: none;
  }
  &:hover {
    transform: scale(1.2);
  }
  position: sticky;
  margin-left: 30px;
  z-index: 2;
  top: 200px;
  height: 100px;
  display: 'flex';
  justify-content: 'center';
`;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'claps-button': ClapsBtnProps;
    }
  }
}

interface ClapsBtnProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  size: string;
  color: string;
  defaultcount: number;
  innercount: number;
}

export const ClapsButton = ({ path }: { path: string }) => {
  const [identity] = useIdentity();
  const [defaultCount, innerCount, postDB] = useMLab(path, identity);
  useEffect(() => {
    const clapDoneCallback = async (e: CustomEvent) => {
      await postDB({ identity, path, count: e.detail.count });
    };
    const debounceCallback = debounce(clapDoneCallback, 800);
    document.addEventListener('clapDone', debounceCallback);
    return () => {
      document.removeEventListener('clapDone', debounceCallback);
    };
  }, [identity]);
  return (
    <ClapsBtn>
      <claps-button size="2rem" color="#4caf50" defaultcount={defaultCount} innercount={innerCount} />
    </ClapsBtn>
  );
};
