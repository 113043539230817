import { useState, useEffect } from 'react';
const Fingerprint2 = require('fingerprintjs2');

function useIdentity() {
  const [identity, setIdentity] = useState<string>('');
  useEffect(() => {
    const options = { excludes: { enumerateDevices: true, fonts: true, audio: true, canvas: true, webgl: true, excludeAdBlock: true } };
    if (window.requestIdleCallback) {
      window.requestIdleCallback(async () => {
        Fingerprint2.get(options, (components: any) => {
          const values = components.map((component: any) => component.value);
          const result = Fingerprint2.x64hash128(values.join(''), 31);
          setIdentity(result);
        });
      });
    } else {
      setTimeout(async () => {
        Fingerprint2.get(options, (components: any) => {
          const values = components.map((component: any) => component.value);
          const result = Fingerprint2.x64hash128(values.join(''), 31);
          setIdentity(result);
        });
      }, 500);
    }
  }, []);
  return [identity];
}

export default useIdentity;
