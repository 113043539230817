// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

export const debounce = (func: any, wait: number) => {
  let timeout: number | undefined;
  return function executedFunction(...args: any) {
    const later = () => {
      timeout = undefined;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
