import { useState, useEffect } from 'react';

interface IPostDB {
  identity: string;
  path: string;
  count?: number;
  setInnerCount?: React.Dispatch<React.SetStateAction<number | 0>>;
  setTotalCount?: React.Dispatch<React.SetStateAction<number | 0>>;
}

const getClapsFromDB = async ({
  identity,
  path,
  setInnerCount,
  setTotalCount,
}: IPostDB & {
  setTotalCount: Pick<IPostDB, 'setTotalCount'>;
  setInnerCount: Pick<IPostDB, 'setInnerCount'>;
}) => {
  try {
    const result = await // await fetch(`http://localhost:8080/webhook?identity=${identity}&path=${path}`, {
    (
      await fetch(`https://asia-northeast1-neon-nexus-284714.cloudfunctions.net/blog-claps/webhook?identity=${identity}&path=${path}`, {
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'content-type': 'application/json',
        },
        method: 'GET',
        referrer: 'no-referrer',
      })
    ).json();
    setInnerCount(result.innerCount || 0);
    setTotalCount(result.totalCount || 0);
  } catch (e) {
    console.error(e);
  }
};

const postDB = async ({ identity, path, count }: IPostDB) => {
  try {
    await // await fetch('http://localhost:8080/webhook', {
    (
      await fetch('https://asia-northeast1-neon-nexus-284714.cloudfunctions.net/blog-claps/webhook', {
        body: JSON.stringify({
          identity,
          path,
          count,
        }),
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        referrer: 'no-referrer',
      })
    ).json();
  } catch (e) {
    console.error(e);
  }
};

function useMLab(path: string, identity: string): [number, number, ({ identity, path, count }: IPostDB) => Promise<void>] {
  const [innerCount, setInnerCount] = useState<number>(0);
  const [defaultCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (!identity) return;
    getClapsFromDB({ identity, path, setInnerCount, setTotalCount });
    // fetch current user's claps and set it to innercount
  }, [identity]);

  return [defaultCount, innerCount, postDB];
}

export default useMLab;
